<script setup lang="ts">
    import * as basicScroll from 'basicscroll';

    interface AwardItem {
        src: string;
        title: string;
    }
    const awardItems = ref<AwardItem[]>([
        {
            src: 'https://assets.woom.com/awards/award-staatspreis-2024.jpg',
            title: 'Award österreichischer Staatspreis Marketing 2024',
        },
        {
            src: 'https://assets.woom.com/awards/award-dia-2023.jpg',
            title: 'Award Logo design & innovation award 2023',
        },
        {
            src: 'https://assets.woom.com/awards/award-reddot-2023.jpg',
            title: 'reddot award 2023',
        },
        {
            src: 'https://assets.woom.com/awards/award-taipei-2023.jpg',
            title: 'Award Logo Taipei Cycle d&i awards 2023',
        },
        {
            src: 'https://assets.woom.com/awards/award-gia-22-winner.jpg',
            title: 'German innovation award 2022 winner',
        },
        {
            src: 'https://assets.woom.com/awards/award-if-design-2022.jpg',
            title: 'if design award 2022',
        },
        {
            src: 'https://assets.woom.com/awards/award-reddot-2022.jpg',
            title: 'reddot award 2022',
        },
        {
            src: 'https://assets.woom.com/awards/award-gda-2021.jpg',
            title: 'Award Logo GDA 2021',
        },
        {
            src: 'https://assets.woom.com/awards/award-adesignaward.jpg',
            title: 'Award A´Design',
        },
        {
            src: 'https://assets.woom.com/awards/award-good-design.jpg',
            title: 'Award good design',
        },
        {
            src: 'https://assets.woom.com/awards/award-bicycle-brand-contest.jpg',
            title: 'Award brand of the year 2017',
        },
    ]);

    const isMounted = ref(false);
    const isTablet = ref();
    const noScroll = ref(false);
    const awardsScrollContainer = ref<Element | null>(null);

    useMediaQuery([
        {
            query: defaultQueries.max.tablet,
            ref: isTablet,
        },
    ]);

    const awardWidth = computed(() => {
        return isTablet.value ? 70 : 90;
    });

    const awardWidthGap = computed(() => {
        return isTablet.value ? awardWidth.value + 32 : awardWidth.value + 16; // gap-8 and lg:gap-4
    });

    const awardsAvailable = computed(() => {
        return isTablet.value ? Math.ceil(awardItems.value.length / 2) + 2.5 : Math.ceil(awardItems.value.length) + 2.5;
    });

    const scrollAmountX = computed(() => {
        if (import.meta.client) {
            const screenSize = window.innerWidth;
            const container = awardWidthGap.value * awardsAvailable.value;
            return container - screenSize;
        }
        return 0;
    });

    const awardsContainerOffset = computed(() => {
        return {
            'padding-left': `${noScroll.value ? 0 : awardWidth.value}px`,
        };
    });

    watch([isMounted, isTablet], async () => {
        if (!isMounted.value || isTablet.value === undefined) return;
        await nextTick();

        if (scrollAmountX.value < 0) {
            noScroll.value = true;
            return;
        }

        const created = basicScroll.create({
            elem: awardsScrollContainer.value,
            from: 'top-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--translateX': {
                    from: '0',
                    to: `-${scrollAmountX.value}px`,
                    timing: (t) => t * t,
                },
            },
        });
        created.start();
    });

    onMounted(async () => {
        isMounted.value = true;
    });
</script>
<template>
    <client-only>
        <div class="mol-awards my-5 overflow-hidden md:my-6">
            <div
                :class="[
                    'grid auto-cols-min grid-flow-col grid-rows-2 gap-8 transition-transform duration-300 ease-out will-change-transform lg:grid-rows-1 lg:gap-4',
                    { 'mx-8 justify-between': noScroll },
                ]"
                ref="awardsScrollContainer"
                :style="awardsContainerOffset">
                <div
                    v-for="award in awardItems"
                    class="award flex items-center justify-center max-lg:even:translate-x-14"
                    :style="`width: ${awardWidth}px`">
                    <atm-image
                        :key="award.title"
                        class="hover:scale-100 md:scale-75 md:transition-transform"
                        image-loading="lazy"
                        sizes="180px"
                        :src="award.src"
                        :alt="award.title" />
                    <span class="sr-only">{{ award.title }}</span>
                </div>
            </div>
        </div>
    </client-only>
</template>

<style scoped>
    .mol-awards > div {
        transform: translateX(var(--translateX));
    }
</style>
